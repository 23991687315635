import React, { useEffect } from "react";
import lottie from "lottie-web";
import loadingDark from "./loading-dark.json";
import loadingLight from "./loading-light.json";
import loadingHouse from "./house-load.json";
import loadingHouseLight from "./house-load-light.json";
import { twMerge } from "tailwind-merge";

interface Props {
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  dark?: boolean;
  global?: boolean;
}

export default function LoadingSpinner ({ style, className, id, dark = true, global }: Props): JSX.Element {
  const [animation, setAnimation] = React.useState<any>(null);

  useEffect(() => {
    if (animation) {
      return;
    }
    const iconMenu = document.querySelector(id ? `#${id}` : "#loadingSpinner");

    if (!iconMenu) return;

    const instance = lottie.loadAnimation({
      container     : iconMenu,
      animationData : global ? (dark ? loadingHouse : loadingHouseLight) : (dark ? loadingDark : loadingLight),
      renderer      : "svg",
      autoplay      : true,
      loop          : true
    });

    setAnimation(iconMenu);
    return () => instance.destroy();
  }, []);

  return (
    <>
      <div
        style={{
          ...style
        }}
        id={id || "loadingSpinner"}
        className={twMerge("loadingspinner h-5 w-5", className)} />
    </>
  );
}
