import { FirebaseError } from "firebase/app"
import {
  AuthError,
  EmailAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  updatePassword,
} from "firebase/auth"

const DEFAULT_LANGUAGE = "de"

export function isFirebaseError(error: unknown): error is FirebaseError {
  return (error as FirebaseError).code !== undefined
}

export function isFirebaseAuthError(error: unknown): error is AuthError {
  return (error as AuthError).code !== undefined
}

export const fetchSignInMethods = async (email: string) =>
  fetchSignInMethodsForEmail(getAuth(), email)

export const fetchCanSignInWithPassword = async (email: string): Promise<boolean> => {
  const signInMethods = await fetchSignInMethodsForEmail(getAuth(), email)

  if (signInMethods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1) {
    // User can sign in with email/password.
    return true
  }

  // if (signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
  //   // User can sign in with email/link.
  // }

  return false
}

export const signUpWithPassword = async (email: string, password: string) => {
  try {
    await createUserWithEmailAndPassword(getAuth(), email, password)
  } catch (error) {
    throw error
  }
}

export const signInWithPopupGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider()

    return signInWithPopup(getAuth(), provider)
  } catch (error) {
    console.error(error)

    throw error
  }
}

export const signInWithPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(getAuth(), email, password)
  } catch (error) {
    console.error(error)

    throw error
  }
}

export const sendSignInLink = async (email: string, lang: string = DEFAULT_LANGUAGE) => {
  const auth = getAuth()

  auth.languageCode = lang === "de" ? "de" : "en"

  const baseUrl = process.env.NEXT_PUBLIC_WEB_BASE_URL?.endsWith("/")
    ? `${process.env.NEXT_PUBLIC_WEB_BASE_URL}${lang}/`
    : `${process.env.NEXT_PUBLIC_WEB_BASE_URL}/${lang}/`

  const encodedEmail = encodeURIComponent(email)

  //Not receommended to add email here but it's ok for now
  const actionCodeSettings = {
    url: baseUrl + "magic?ia=" + encodedEmail,
    handleCodeInApp: true,
  }

  try {
    await sendSignInLinkToEmail(getAuth(), email, actionCodeSettings)
  } catch (error) {
    console.error(error)

    throw error
  }
}

export const signInWithEmailLinkOrToken = async (
  email?: string,
  token?: string,
  href?: string,
): Promise<void> => {
  const auth = getAuth()

  try {
    if (token) {
      await signInWithCustomToken(auth, token)
    } else if (!!href && isSignInWithEmailLink(auth, href)) {
      await signInWithEmailLink(auth, email || "", href)
    }

    throw new Error("Cannot sign in with custom token or email link")
  } catch (error) {
    console.error(error)

    throw error
  }
}

export const requestPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(getAuth(), email, {
      url: process.env.NEXT_PUBLIC_WEB_BASE_URL!,
    })
  } catch (error) {
    throw error
  }
}

export const changePassword = async (newPassword: string) => {
  try {
    const auth = getAuth()
    const currentUser = auth.currentUser

    if (currentUser) {
      await updatePassword(currentUser, newPassword)
    }
  } catch (error) {
    throw error
  }
}

export const signOutUser = async () => {
  try {
    const auth = getAuth()

    await auth.signOut()
  } catch (error) {
    console.error(error)

    throw error
  }
}
